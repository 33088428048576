const azhazha = [

    {
        key: 'month_1',
        name: '1 месяц',
        price: 19000,
        isChecked: false,
        type: 'month',
    },

    {
        key: 'tariff_0',
        name: '1 закупка',
        price: 12500,
        isChecked: false,
        type: 'tariff',
    },

    {
        key: 'tariff_1',
        name: '4 закупки',
        price: 45000,
        isChecked: false,
        type: 'tariff',
    },

    {
        key: 'tariff_2',
        name: '10 закупок',
        price: 106250,
        isChecked: false,
        type: 'tariff',
    },

    {
        key: 'podgotovka_tendera',
        name: 'Подготовка тендерной документации',
        price: 5500,
        isChecked: false,
        disabled : false,
        type: 'common',
    },

    {
        key: 'podacha',
        name: 'Подача заявки',
        price: 3000,
        isChecked: false,
        disabled : false,
        type: 'common',
    },

    {
        key: 'uchastie',
        name: 'Участие в торгах',
        price: 4000,
        isChecked: false,
        disabled : false,
        type: 'common',
    },
    {
        key: 'podpisanie',
        name: 'Подписание контракта, составление протокола разногласий',
        price: 2500,
        isChecked: false,
        disabled : false,
        type: 'common',
    },

    {
        key: 'ecp_0',
        name: '8 Федеральных площадок на 12 месяцев',
        price: 4000,
        isChecked: false,
        type: 'ecp',
    },
    {
        key: 'ecp_1',
        name: 'USB-ключ, лицензия СКЗИ Крипто Про на 15 месяцев',
        price: 2100,
        isChecked: false,
        type: 'ecp',
    },
    {
        key: 'ecp_2',
        name: 'Площадки, входящие в АЭТП на 12 месяцев',
        price: 1050,
        isChecked: false,
        type: 'ecp',
    },
    {
        key: 'nastroika',
        name: 'Настройка рабочего места для работы на площадках',
        price: 1500,
        isChecked: false,
        type: 'other',
    },
    {
        key: 'registracia',
        name: 'Регистрация в ЕИС включая настройку рабочего места',
        price: 5000,
        isChecked: false,
        type: 'other',
    },
    {
        key: 'podgotovka_dokumentov',
        name: 'Подготовка документов для открытия спецсчёта для участия в закупках',
        price: 3000,
        isChecked: false,
        type: 'other',
    },
    {
        key: 'monitoring',
        name: 'Мониторинг тендеров, рассылка 2 раза в неделю',
        price: 4000,
        isChecked: false,
        disabled : false,
        type: 'other',
    },
    {
        key: 'podgotovka_zaiavok',
        name: 'Подготовка первых частей заявок',
        price: 3000,
        isChecked: false,
        type: 'other',
    },

    {
        key: 'oformlenie',
        name: 'Оформление банковской гарантии',
        price: 3000,
        isChecked: false,
        type: 'other',
    },

    {
        key: 'zhaloba',
        name: 'Подача жалобы в УФАС',
        price: 7000,
        isChecked: false,
        type: 'other',
    }

];

export default azhazha
