import React from 'react';
import './App.css';

import {isMobileOnly} from 'react-device-detect';

import Splash from './components/Splash';
import Calc from "./components/calc/Calc";
import {useDispatch} from "react-redux";

import {setData, setFaq} from "./actions";

import calcData from "./components/calc/CalcData";
import faqData from "./components/faq/FaqData";
import Faq from "./components/faq/Faq";
import About from "./components/About";

function App() {
    const dispatch = useDispatch();
    dispatch(setData(calcData));
    dispatch(setFaq(faqData));


    return (
        <>
            <header>
                <div className="logo">ПроТоргСервис</div>

                <div className="contacts">
                    {isMobileOnly ? <a href="tel:88001004753" className="phone">8 (800) 100-47-53</a> : <div className="phone">8 (800) 100-47-53</div>}
                    <div className="email">torg@protorgservice.ru</div>
                </div>
            </header>
            <div className="hr"></div>

            <Splash/>

            <Calc/>

            <Faq/>

            <About />

            <footer>
                <div className="logo">ПроТоргСервис</div>

                <div className="contacts">
                    {isMobileOnly ? <a href="tel:88001004753" className="phone">8 (800) 100-47-53</a> : <div className="phone">8 (800) 100-47-53</div>}
                    <div className="email">torg@protorgservice.ru</div>
                </div>
            </footer>

        </>
    );
}

export default App;

//blacklist: [/^\/request/,/^\/_/,/\/[^/?]+\.[^/]+$/],
