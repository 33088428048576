const faqData = [
    {
        question: "Что такое тендер?",
        isOpened : true,
        answer: "Тендер — это конкурентная форма отбора предложений на поставку товаров, оказания услуг или выполнения работ по заранее объявленным в тендерной документации условиям, в соответствующие сроки на принципах состязательности, справедливости и эффективности. Тендеры проводятся как государственными и муниципальными органами в виде закупок, так и коммерческими организациями. В Российской Федерации согласно действующему законодательству, используется термин «государственные закупки». Термины государственные закупки и тендер выступают в качестве синонимов и являются равными значениями. Для приобретения товаров, работ, услуг заказчики осуществляют закупки через определенные процедуры, согласно закону или внутренними регламентами организаций."
    },
    {
        question: "Какими законами регулируются закупки в Российской Федерации?",
        isOpened : false,
        answer: "Закупки в Российской Федерации регулируются следующими законами, а именно:\n" +
            "- Федеральный закон \"О контрактной системе в сфере закупок товаров, работ, услуг для обеспечения государственных и муниципальных нужд\" от 05.04.2013 №44-ФЗ;\n" +
            "-  Федеральный закон \"О закупках товаров, работ, услуг отдельными видами юридических лиц\" от 18.07.2011 №223-ФЗ;\n" +
            "-  Федеральный закон \"О государственном оборонном заказе\" от 29.12.2012 №275-ФЗ;\n" +
            "- Постановление Правительства Российской Федерации от 01.07.2016 №615 (ред. от 12.04.2019) \"О порядке привлечения подрядных организаций для оказания услуг и (или) выполнения по капитальному ремонту общества в многоквартирном доме и порядке осуществления закупок товаров, работ, услуг в целях выполнения функций специализированной некоммерческой организации, осуществляющей деятельность, направленную на обеспечение проведения капитального ремонта общего имущества в многоквартирным домах\".\n" +
            "Согласно вышеуказанным законам, там четко прописаны все возможные формы проведения торгов. \n" +
            "Важно отметить, что при принятии решения участия в закупки, необходимо знать, в рамках какого закона размещена закупочная процедура.\n"
    },
    {
        question: "Что такое тендерное сопровождение?",
        isOpened : false,
        answer: "Тендерное сопровождение - это процесс сопровождения участника закупки от оформления электронно-цифровой подписи (ЭЦП) до подписания контракта, а именно:\n" +
            " - оформление ЭЦП;\n" +
            " - настройка рабочего места;\n" +
            " - регистрация в Единой информационной системе;\n" +
            " - открытие специального счета для участия в закупках;\n" +
            " - мониторинг тендеров;\n" +
            " - подготовка первых частей закупок;\n" +
            " - подготовка тендерной документации;\n" +
            " - подача заявки;\n" +
            " - участие в торгах;\n" +
            " - оформление банковской гарантии;\n" +
            " - подписание контракта, составление протокола разногласий.\n"
    },
    {
        question: "Какие гарантии может дать тендерное сопровождение?",
        isOpened : false,
        answer: "Гарантировать Вам победу в той или иной закупочной процедуре не сможет ни одна компания. Победа прежде всего зависит от Вас самих, на сколько Вы конкурентоспособны. Наша задача, чтобы Ваша заявка на участие в торгах была допущена. "
    },
    {
        question: "Что такое заявка на аукцион и что в нее входит?",
        isOpened : false,
        answer: "Представляет собой письменное уведомление участника на согласие участия в той или иной закупочной процедуре. Законом не предусмотрена обязательная форма для подачи заявки. \n" +
            "     Собственно заявка на участие в закупочной процедуре состоит из двух частей, а именно:\n" +
            "     I часть заявки - представляет собой как правило, согласие участника закупки, а в иных случаях и предоставление конкретных показателей товаров, работ, услуг;\n" +
            "     II часть заявки - представляет собой сведения об участнике закупки, разрешительные документы (лицензии, допуски СРО) и иные документы.\n" +
            "     В случае, если в обеих частях заявки будет допущена ошибка, заявка подлежит отклонению, не смотря на то, что компания предложила выгодные условия и победила.\n"
    },
    {
        question: "Что такое первая часть заявки?",
        isOpened : false,
        answer: "Первая часть заявки является составляющим звеном заявки участника на участие в закупочной процедуре. Как правило, она не имеет сведений об участниках, иначе это будет противоречить честной конкуренции.\n" +
            "      Первая часть заявки состоит из: \"Форма 1\" и \"Форма 2\".\n" +
            "\"Форма 1\" - это согласие участника закупки на поставку товаров, выполнения работ, оказания услуг на условиях предусмотренных документации закупочной процедуры.\n" +
            "\"Форма 2\" - это описание товаров, работ, услуг которые должен выполнить Поставщик/Подрядчик/Исполнитель, иными словами конкретные показатели. Заполнение указанной формы ведет к определенным трудностям, так как здесь необходимо учитывать соответствующие ГОСТы и иные технические характеристики, если речь идет о поставке товаров, выполнении работ. Данная форма зачастую заполняется в виде таблицы.\n"
    },
    {
        question: "Что такое вторая часть заявки?",
        isOpened : false,
        answer: " Вторая часть заявки содержит сведения об участнике закупочной процедуры.\n" +
            " В нее входит:\n" +
            " - Наименование организации, ее реквизиты. В случае, если заявка подается физическим лицом, тогда указывается ФИО гражданина, место жительства, ИНН;\n" +
            " - Разрешительные документы (лицензия, допуски СРО) и иные документы, в том случае, если это предусмотрено по условиям документации;\n" +
            " - Копии документов о соответствии товара установленным требованиям в Российской Федерации, в том случае, если это предусмотрено по условиям документации;\n" +
            "  -  Копия решения о совершении крупной сделки. \n" +
            "\n" +
            "Стоит отметить, что учредительные документы не прикладываются, так как они подгружаются оператором электронной площадки из единого реестра участников закупки. \n"
    },
    {
        question: "Что такое банковская гарантия?",
        isOpened : false,
        answer: "Банковская гарантия - это документ обязывающий банк (гарант) выплатить заказчику, организатору торгов (бенефициару) определенную сумму в соответствии с договором в том случае, если исполнитель (принципал) не исполнил в положенный срок своих обязательств по отношению к заказчику (бенефициару).\n" +
            "В чем преимущества спросите Вы ?\n" +
            "-  Денежные средства компании не замораживаются;\n" +
            "- Стоимость банковской гарантии намного ниже, чем обеспечение исполнения контракта.\n" +
            "В случае недобросовестного исполнения контракта Исполнителем (Принципалом), Заказчик (бенефициар) вправе обратиться в банк (Гарант), который выдал банковскую гарантию, чтобы потребовать с него неустойку.\n" +
            "Этот финансовый инструмент в сфере закупок, очень часто используется по сравнению с внесением денежных средств на расчетный счет Заказчика.\n" +
            "Банковская гарантия может также применяться для обеспечения заявки на участие в закупочных процедурах по 44-ФЗ. \n"
    },
    {
        question: "Если я выиграл контракт, но не смогу его выполнить?",
        isOpened : false,
        answer: "В случае, если Вы не грубо нарушаете условия контракта, тогда Вам проще договориться с Заказчиком и найти мирный выход из ситуации. \n" +
            "                  Конечно же, в случае грубого нарушения условий исполнения контракта, Заказчик будет вынужден расторгнуть контракт в одностороннем порядке и занести Вашу организацию и ее учредителей в реестр недобросовестных поставщиков. При этом, нахождение в реестре недобросовестных поставщиков исключает участие Вашей организации в закупках на 2 года. \n"
    }/*,
    {
        question : "",
        answer : ""
    },*/
]

export default faqData