import React from "react";

const CalcMonth = (props) => {
    return (
        <tr key={props.i.key}>
            <td></td>
            <td>
                <input type="checkbox"
                       value={props.i.key}
                       onChange={props.monthOnChangeHandle}
                       checked={props.i.isChecked} />
            </td>
            <td><span className={props.i.isChecked ? 'strong' : undefined}>{props.i.name}</span></td>
            <td style={{lineHeight: 0.8 + 'em'}}><span className={props.i.isChecked ? 'strong' : undefined}>{props.i.price.toLocaleString()}<br/><small style={{lineHeight: 0.1 + 'em',fontSize:.75 + 'em'}}>+ 0,5% от выигрыша</small></span></td>
        </tr>
    );
}

export default CalcMonth
