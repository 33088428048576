import React, {useState} from "react";
import preloader from "../i/preloader.svg";

const Form = (props) => {

    const [phone, setPhone] = useState('');

    const [q, setQ] = useState({pending: false, sent: false, failed: false});

    const [i, setI] = useState(5);

    const formSubmitHandler = (e) => {
        e.preventDefault();

        setQ({...q, pending: true});

        fetch("/request.php", {
            method: 'POST',
            body: JSON.stringify({
                type: props.type,
                phone: phone,
                data: props.data,
                sum: props.sum,
            })
        })
            .then(r => r.json())
            .then(
                (ok) => {
                    if (ok) {
                        setQ({
                            ...q,
                            sent: true
                        });
                    } else {
                        setQ({
                            ...q,
                            failed: true
                        });

                        let j = 5;
                        const interval = setInterval(() => {
                            if (j === 1) {
                                clearInterval(interval);
                                setQ({
                                    ...q,
                                    pending: false,
                                    sent: false,
                                    failed: false
                                });
                                setI(5);
                            } else {
                                j--;
                                setI(j);
                            }
                        }, 1000);
                    }
                }
            )
    }

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    }


    return (
        <div className="form-container">
            {q.sent ?
                <>
                    <div className="form-title text-success">Ваша заявка принята. Пожалуйста, ожидайте звонка.</div>
                </>
                :
                <>
                    {
                        q.failed ?
                            <div className="form-title text-error">К сожалению, заявка не была отправлена. Попробуйте
                                снова через {i}.</div>
                            :
                            <>
                                <div className="form-title">{props.title}</div>

                                <form onSubmit={formSubmitHandler}>


                                    <input type="text"
                                           placeholder="Введите номер телефона"
                                           value={phone}
                                           disabled={q.pending}
                                           required={true}

                                           onChange={handlePhoneChange}
                                    />
                                    <button type="submit" disabled={q.pending}>
                                        {
                                            q.pending ? <img src={preloader} alt=""/> : 'Заказать'
                                        }
                                    </button>
                                </form>
                            </>

                    }
                </>
            }
        </div>
    );
}

export default Form