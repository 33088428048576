import React from "react";
import Form from "../Form";

import {useDispatch, useSelector} from "react-redux";
import {toggleFaq} from "../../actions";

const Faq = () => {

    const faqData = useSelector((state) => state.faq);

    const dispatch = useDispatch();

    const handleToggleQuestion = (e) => {
        dispatch(toggleFaq(e.target.id));
    }


    return (
        <section className="faq">
            <div className="title">Часто задаваемые вопросы</div>
            <div className="subtitle">Если Вашего вопроса нет в списке, <br className="desktop-inline"/>закажите
                бесплатную консультацию в форме ниже.
            </div>

            <div className="container">

                {faqData.map((o, q) => {
                    return (
                        <div className="each" key={q}>
                            <div className="question" onClick={handleToggleQuestion} id={q}>{q + 1}. {o.question}</div>
                            {o.isOpened ? <div className="answer">
                                {o.answer.split("\n").map((i, k) => {
                                    return <span key={k}>{i}<br/></span>;
                                })}
                            </div> : ''}
                        </div>
                    )
                })}

                <Form title="Закажите бесплатную консультацию:" type="faq"/>

            </div>
        </section>
    );
}

export default Faq