const actionSetData = (entities) => ({
    type: 'SET_DATA',
    payload: entities
});

const actionToggleMonth = (i) => {
    return {
        type: 'TOGGLE_MONTH',
        payload: i
    }
};

const actionToggleTariff = (i) => {
    return {
        type: 'TOGGLE_TARIFF',
        payload: i
    }
};

const actionToggleEcp = (i) => {
    return {
        type: 'TOGGLE_ECP',
        payload: i
    }
};

const actionToggleOther = (i) => {
    return {
        type: 'ITEM_IS_CHECKED',
        payload: i
    }
};

const actionSetFaq = (entities) => ({
    type: 'SET_FAQ',
    payload: entities
});

const actionToggleFaq = (k) => {
    return {
        type: 'FAQ_IS_OPENED',
        payload: k
    }
}

export const setData = (d) => actionSetData(d)
export const toggleOther = (i) => actionToggleOther(i)
export const toggleMonth = (i) => actionToggleMonth(i)
export const toggleTariff = (i) => actionToggleTariff(i)
export const toggleEcp = (i) => actionToggleEcp(i)

export const setFaq = (e) => actionSetFaq(e)
export const toggleFaq = (k) => actionToggleFaq(k)
