import React from 'react';
import {Map, Marker} from 'yandex-map-react';

const ContactMap = (props) => {
    return (
        <Map onAPIAvailable={() => {
            console.log('API loaded');
        }}
             center={props.coords}
             zoom={16}
             width="100%"
             height={400}
             state={{
                 controls:['zoomControl'],
                 behaviors: [/*'drag'*/]
             }}
             /*onClick={(e)=>{
                 console.log(e);
             }}*/
             onWheel={(e) => {
                 e.preventDefault()
             }}>
            <Marker lat={props.coords[0]} lon={props.coords[1]} />
        </Map>
    );
}

export default ContactMap