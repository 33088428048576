import React from "react";

const CalcOther = (props) => {
    return (
        <tr>
            <td>
                <input type="checkbox"
                       value={props.i.key}
                       onChange={props.otherOnChangeHandle}
                       checked={props.i.isChecked}
                       disabled={props.i.disabled}
                />
            </td>
            <td colSpan="2"><span className={props.i.isChecked ? 'strong' : undefined}>{props.i.name}</span></td>
            <td><span className={props.i.isChecked ? 'strong' : undefined}>{props.i.price.toLocaleString()}</span></td>
        </tr>
    );
}

export default CalcOther
