import React from "react";

const CalcTariff = (props) => {
    return (
        <tr key={props.i.key}>
            <td></td>
            <td>
                <input type="checkbox"
                       value={props.i.key}
                       onChange={props.tariffOnChangeHandle}
                       checked={props.i.isChecked} />
            </td>
            <td><span className={props.i.isChecked ? 'strong' : undefined}>{props.i.name}</span></td>
            <td><span className={props.i.isChecked ? 'strong' : undefined}>{props.i.price.toLocaleString()}</span></td>
        </tr>
    );
}

export default CalcTariff