import {combineReducers} from "redux";

const toggleOther = (state, action) => {
    const {payload} = action;
    const {key, isChecked} = payload;

    return state.map(item => item.key === key ? {...item, isChecked: isChecked} : item);
}

const toggleMonth = (state, action) => {
    const {payload} = action;
    const {key, isChecked} = payload;

    return state.map(item => item.key === key ? {...item, isChecked: isChecked} : item)
        .map(item => item.type === 'common' ? {...item, isChecked: isChecked, disabled: isChecked} : item)
        .map(item => item.key === 'monitoring' ? {...item, isChecked: isChecked, disabled: isChecked} : item)
        .map(item => item.key !== key && item.type === 'tariff' ? {...item, isChecked: false} : item);
}

const toggleTariff = (state, action) => {
    const {payload} = action;
    const {key, isChecked} = payload;

    return state.map(item => item.key === key ? {...item, isChecked: isChecked} : item)
        .map(item => item.type === 'common' ? {...item, isChecked: isChecked, disabled: isChecked} : item)
        .map(item => item.key !== key && item.type === 'month' ? {...item, isChecked: false} : item)
        .map(item => item.key === 'monitoring' ? item.disabled ? {...item, isChecked: false, disabled: false} : item : item)
        .map(item => item.key !== key && item.type === 'tariff' ? {...item, isChecked: false} : item);
}

const toggleEcp = (state, action) => {
    const {payload} = action;
    const {key, isChecked} = payload;

    return state.map(item => item.key === key ? {...item, isChecked: isChecked} : item)
        .map(item => item.key !== key && item.type === 'ecp' ? {...item, isChecked: false} : item)
        .map(item => key === 'ecp_1' && item.key === 'ecp_0' ? {...item, isChecked: isChecked} : item)
        .map(item => key === 'ecp_2' && ['ecp_0', 'ecp_1'].indexOf(item.key) !== -1 ? {
            ...item,
            isChecked: isChecked
        } : item);
}

const data = (state = [], action) => {
    switch (action.type) {
        case 'SET_DATA':
            return [...state, ...action.payload]
        case 'ITEM_IS_CHECKED':
            return toggleOther(state, action);
        case 'TOGGLE_MONTH':
            return toggleMonth(state, action);
        case 'TOGGLE_TARIFF':
            return toggleTariff(state, action);
        case 'TOGGLE_ECP':
            return toggleEcp(state, action);

        default:
            return state;
    }
}

const toggleFaq = (state, action) => {
    const {payload} = action;
    return state.map((item, k) => {
            return {...item, isOpened: k == payload}
        }
    );
}

const faq = (state = [], action) => {
    switch (action.type) {
        case 'SET_FAQ':
            return [...state, ...action.payload]
        case 'FAQ_IS_OPENED':
            return toggleFaq(state, action)

        default:
            return state;
    }
}

const reducer = combineReducers({
    data,
    faq
})

export default reducer
