import React from "react";

import refund from '../i/advantages/refund.svg';
import twentyFour from '../i/advantages/twentyFour.svg';
import Russia from '../i/advantages/Russia.svg';
import accountant from '../i/advantages/accountant.svg';
import guarantee from '../i/advantages/guarantee.svg';
import lawyer from '../i/advantages/lawyer.svg';

const Advantages = () => {
    return (
        <div className="advantages">
            <div className="subtitle">Преимущества сотрудничества с компанией «ПроТоргСервис»</div>

            <div className="container">
                <div className="each">
                    <img src={refund} alt="" />
                    <strong>100% возврат средств</strong><br /><span>при отклонении заявки на участие по нашей вине</span>
                </div>
                <div className="each">
                    <img src={twentyFour} alt="" />
                    <strong>Круглосуточная поддержка</strong><br /><span>для клиентов, заключивших договор</span>
                </div>
                <div className="each">
                    <img src={Russia} alt="" />
                    <strong>Вне зависимости <br className="mobile-inline" />от часовых поясов</strong><br /><span>оказываем услуги на территории всей РФ</span>
                </div>
                <div className="each">
                    <img src={accountant} alt="" />
                    <strong>Консультации бухгалтера</strong><br /><span>по закрытию контракта</span>
                </div>
                <div className="each">
                    <img src={guarantee} alt="" />
                    <strong>Оформление банковской гарантии</strong><br /><span>даже для «молодой» компании</span>
                </div>
                <div className="each">
                    <img src={lawyer} alt="" />
                    <strong>Юридическая поддержка</strong><br /><span>для решения вопросов в сфере закупок</span>
                </div>
            </div>
        </div>
    );
}

export default Advantages