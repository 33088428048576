import React from "react";
import ceo_0 from "../i/ceo_0.png";
import ContactMap from "./ContactMap";
import {isMobile} from "react-device-detect";

const About = () => {
    return (
        <section className="about">
            <div className="title">Контактная информация</div>
            <div className="container">
                <div className="text">
                    <p>
                        Общество с ограниченной ответственностью «ПроСервисКорпорейтед»
                    </p>


                    <p>
                        <strong>ОГРН</strong> 1197847127774, <strong>ИНН</strong> 7811730554
                    </p>
                    <p>
                        <strong>Юридический и почтовый адрес:</strong>
                        <br/>
                        193231, Санкт-Петербург, пр. Товарищеский, д. 22, корп. 2, 83
                    </p>

                    <p>
                        <strong>Телефоны:</strong>
                        <br/>
                        +7 (921) 753-36-03 ({isMobile ? <a href="https://wa.me/79217533603">Написать в WhatsApp</a> : 'Доступен в WhatsApp'})<br/>
                        8 (800) 100-47-53<br/>
                        <strong>Email:</strong>
                        <br/>
                        torg@protorgservice.ru
                    </p>

                    <p>
                        <strong>Офис:</strong>
                        <br/>
                        198188, Санкт-Петербург, ул. Зайцева, д. 41, офис 241
                    </p>
                </div>

                <div className="ceo">
                    <div className="each">
                        <img src={ceo_0} alt=""/>
                        <strong>Старкина Надежда Андреевна</strong>
                        <br/>
                        Генеральный директор
                    </div>
                </div>
            </div>

            <div className="map-container">
                <ContactMap coords={[59.88030606427194, 30.27536299999996]}/>
            </div>

        </section>
    )
}

export default About
