import React from "react";

const Splash = () => {
    return (
        <section className="splash">
            <div className="splash-title">
                <h1 className="title">
                    <span>Тендерное</span><br />
                    <span>сопровождение</span>
                </h1>

                <h2 className="subtitle">
                    по 44-ФЗ, 223-ФЗ и 615-ПП РФ
                </h2>
            </div>

            <iframe width="560" height="315" src="https://www.youtube.com/embed/ETCNb4mASYE" frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>
        </section>
    );
}

export default Splash
