import React from "react";

import {useDispatch, useSelector} from "react-redux";
import {toggleOther, toggleMonth, toggleTariff, toggleEcp} from "../../actions";
import CalcEcp from "./CalcEcp";
import CalcOther from "./CalcOther";
import CalcTariff from "./CalcTariff";
import CalcCommon from "./CalcCommon";

import Advantages from "../Advantages";
import Form from "../Form";
import CalcMonth from "./CalcMonth";

const Calc = () => {
    const dispatch = useDispatch();

    const data = useSelector((state) => state.data);

    const sum = data.filter((o) => o.isChecked && !o.disabled).reduce((s, o) => s + Number(o.price), 0);

    const isTariffChecked = data.filter((o) => o.isChecked).find(o => o.type === 'tariff');
    const isEcpChecked = data.filter((o) => o.isChecked).find(o => o.type === 'ecp');

    const formData = data.filter((o) => o.isChecked && !o.disabled).map(o => o.name);


    const monthOnChangeHandle = (e) => {
        dispatch(toggleMonth({
            key: e.target.value,
            isChecked: e.target.checked
        }))
    };

    const tariffOnChangeHandle = (e) => {
        dispatch(toggleTariff({
            key: e.target.value,
            isChecked: e.target.checked
        }))
    };

    const ecpOnChangeHandle = (e) => {
        dispatch(toggleEcp({
            key: e.target.value,
            isChecked: e.target.checked
        }))
    };

    const otherOnChangeHandle = (e) => {
        dispatch(toggleOther({
            key: e.target.value,
            isChecked: e.target.checked
        }))
    };


    return (
        <section className="calc">
            <div className="title">
                Виды и стоимость услуг
            </div>

            <div className="subtitle">
                Узнайте общую стоимость, выбрав необходимые услуги.
            </div>

            <div className="calc-wrap">
                <table className="table">
                    <thead>
                    <tr>
                        <th></th>
                        <th colSpan="2">Вид <br className="mobile-inline"/>услуги</th>
                        <th>Стоимость<br />в рублях</th>
                    </tr>
                    <tr className="tr-hr">
                        <td colSpan="4">
                            <div className="hr"></div>
                        </td>
                    </tr>
                    </thead>

                    <tbody>

                    <tr className="tr-heading">
                        <td></td>
                        <td colSpan="2">
                            <span className={isTariffChecked ? 'strong' : undefined}>Тендерное сопровождение:</span>
                        </td>
                        <td></td>
                    </tr>


                    {
                        data.filter((o) => o.type === 'month').map((i) => {
                            return (
                                <CalcMonth key={i.key} i={i} monthOnChangeHandle={monthOnChangeHandle}/>
                            );
                        })
                    }

                    {
                        data.filter((o) => o.type === 'tariff').map((i) => {
                            return (
                                <CalcTariff key={i.key} i={i} tariffOnChangeHandle={tariffOnChangeHandle}/>
                            );
                        })
                    }

                    {
                        data.filter((o) => o.type === 'common').map((i) => {
                            return (
                                <CalcCommon key={i.key} i={i} otherOnChangeHandle={otherOnChangeHandle}/>
                            );
                        })
                    }

                    <tr className="tr-heading">
                        <td></td>
                        <td colSpan="2"><span className={isEcpChecked ? 'strong' : undefined}>Электронно-цифровая подпись (ЭЦП):</span>
                        </td>
                        <td></td>
                    </tr>

                    {
                        data.filter((o) => o.type === 'ecp').map((i) => {
                            return (
                                <CalcEcp key={i.key} i={i} ecpOnChangeHandle={ecpOnChangeHandle}/>
                            );
                        })
                    }

                    {
                        data.filter((o) => o.type === 'other').map((i) => {
                            return (
                                <CalcOther key={i.key} i={i} otherOnChangeHandle={otherOnChangeHandle}/>
                            );
                        })
                    }

                    <tr className="tr-hr">
                        <td colSpan="4">
                            <div className="hr"></div>
                        </td>
                    </tr>
                    </tbody>

                    <tfoot>
                    <tr>
                        <th></th>
                        <th colSpan="2">Общая стоимость<br className="mobile-inline" /> выбранных услуг</th>
                        <th>{sum.toLocaleString()} рублей</th>
                    </tr>
                    <tr className="tr-hr">
                        <td colSpan="4">
                            <div className="hr"></div>
                        </td>
                    </tr>
                    </tfoot>
                </table>

                <p className="hint">
                    Для пунктов «Подготовка тендерной документации», «Подписание контракта, составление протокола
                    разногласий» и «Подготовка первых частей заявок» указана минимальная стоимость, окончательная стоимость зависит от сложности закупки.
                </p>
                <Form title="Закажите выбранные услуги:" type="calc" data={formData} sum={sum}/>

            </div>

            <Advantages/>

        </section>
    );
}

export default Calc
